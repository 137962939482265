import "@innovatrics/dot-auto-capture-ui/document"
import "@innovatrics/dot-document-auto-capture"

/*
 * When component is initiliazed, sam.wasm file will be fetched from http://localhost:3000/sam.wasm.
 * That's why sam.wasm file need to be placed in root of public folder.
 */
class DocumentCapture extends React.Component {

  refDoc = React.createRef()
  refCam = React.createRef()
  state = {}   


  componentDidMount() {
    this.passProps()
  }
  
  componentDidUpdate() {
    this.passProps()
    if (MC.getFieldParamBooleanValue(this.props.data.param, "@continueDetection")) {
      document.dispatchEvent(new CustomEvent("document-auto-capture:control", {detail: { instruction: "continue-detection" }}))
      MC.putFieldParamValue(this.props.data.param, "@continueDetection", false)
    }
  }

  passProps() {
    let camProps = MC.getFieldParamValue(this.props.data.param, "@cameraProps")
    if (MC.isNull(camProps) || !MC.isPlainObject(camProps)) {
      camProps = {}
    }
    camProps = {...camProps, onPhotoTaken: this.handlePhotoTaken, onError: this.handleError, wasmDirectoryPath: "/metada/innovatrics/app/static/document-capture/wasm"}
    this.refCam.current.cameraOptions = camProps
    let uiProps = MC.getFieldParamValue(this.props.data.param, "@uiProps")      
    if (!MC.isNull(uiProps) && MC.isPlainObject(uiProps)) {
      this.refDoc.current.props = uiProps
    }
  }

  handlePhotoTaken = (imageData) => {
    const self = this
    MC.blobToBase64(imageData.image).then((base64) => {
      MC.putFieldParamValue(self.props.data.param, 'value', base64.split(',')[1])
      MC.handleEvent(self.props.data, 'change')
    })
  }

  handleError = (error) => {
    this.props.data.flow.endOperationException('SYS_TechnicalExc', error)
  }

  render() {
    return (
      <div style={{position: 'relative'}}>
        <x-dot-document-auto-capture id="x-dot-document-auto-capture" ref={this.refCam} />
        <x-dot-document-auto-capture-ui id="x-dot-document-auto-capture-ui" ref={this.refDoc} />    
      </div>
    )
  }
}

MC.registerReactRomponent('documentCapture', DocumentCapture)